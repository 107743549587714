.update-flie{
    position: relative;
}

.update-flie-box{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.update-list{
    width: 450px;
    padding: 4px 10px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px dashed #999;
}

.update-list-left,.update-list-left a{
    display: flex;
    align-items: center;
    flex:1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #035bbd;
    text-decoration: none;
}
.label{
    font-size: 16px;
    line-height: 36px;
}
