.m-no-account-submit-container {
    .login-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
        }
    }
    .table {
        width: 760px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -46%);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.6) !important;
        border-radius: 12px;
        z-index: 2;
        background: rgba(255, 255, 255, 0.94) !important;
        overflow: initial !important;
        .m-form-controller {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 14px;
        }
        .main-title {
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: 40px;
            color: #fff;
            top: -100px;
            font-weight: bold;
            text-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
        }
        .invoice-submit {
            margin-top: 0;
            margin-right: 25px;
            position: absolute;
            top: 20px;
            right: 0;
        }
        .table-title {
            padding: 20px 0 20px 20px;
            font-size: 20px;
            color: #2d7dd4;
            font-weight: bold;
            line-height: 36px;
        }
        .m-form-wrapper {
            padding: 0 20px 40px 20px;
            height: 523px;
            overflow-y: auto;
            box-sizing: border-box;
        }
    }
    .error-alert {
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 4px;
        box-shadow: none;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        background-color: rgb(229, 246, 253);
        display: flex;
        padding: 16px 16px;
        color: rgb(1, 67, 97);
        align-items: center;
        width: 100%;

        .MuiSvgIcon-root {
            color: rgb(3, 169, 244);
            margin-right: 12px;
            padding: 7px 0;
            display: flex;
            font-size: 22px;
            opacity: 0.9;
        }
    }
}
